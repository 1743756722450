import Cookies from "js-cookie";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export const ENTRY_TYPES = {
    team_exp: "Team Work & Experience",
    team_story: "Team Storyline",
    indie_story: "Individual Storyline",
    indie_exp: "Individual Work & Experience",
    story_line: "Storyline",
    single_activity: "Single Activity",
};

export function createEntryType($application_type, $new_type) {
    if ($application_type == '个人') {
        if ($new_type == 'story_line') {
            return 'indie_story'
        } else {
            return 'indie_exp'
        }
    } else {
        if ($new_type == 'story_line') {
            return 'team_story'
        } else {
            return 'team_exp'
        }
    }
}

//individual vs. team
// storyline vs. work & experience (single activity)

export const FORMAT_PROJECT = {
    '23-24EAE': "2024 EAE",
};

const EAE_USER_ID = "eae-user_id";
const EAE_USER_NAM = "eae-user_name";
const EAE_USER_ACCOUNT_COUNTRY = "eae-user_account_country";
const EAE_USER_TYPE = "eae-user-type";
const TARGET_TYPE = "target_type";
const PROJECT_CODE = "eae_project_code";


export function isAdminLogin() {
    let user_type = getUserType();
    let isAdmin = user_type == "admin" || user_type == "advisor";
    return isAdmin;
}

export function setAccountContinent(continent) {
    Cookies.set('eae_account_continent', continent)
}

export function getAccountContinent() {
    return Cookies.get('eae_account_continent')
}


export function setUserType(user_type) {
    Cookies.set(EAE_USER_TYPE, user_type);
}

export function getUserType() {
    return Cookies.get(EAE_USER_TYPE);
}

export function removeUserType() {
    Cookies.remove(EAE_USER_TYPE);
}

export function setAccountId(account_id) {
    Cookies.set("eae-account-id", account_id);
}

export function getAccountId() {
    return Cookies.get("eae-account-id");
}

export function removeAccountId() {
    Cookies.remove("eae-account-id");
}

export function setUserAccountCountry(country) {
    Cookies.set(EAE_USER_ACCOUNT_COUNTRY, country);
}

export function getUserAccountCountry() {
    return Cookies.get(EAE_USER_ACCOUNT_COUNTRY);
}

export function removeUserAccountCountry() {
    Cookies.remove(EAE_USER_ACCOUNT_COUNTRY);
}

export function setUserId(user_id) {
    Cookies.set(EAE_USER_ID, user_id);
}

export function getUserId() {
    return Cookies.get(EAE_USER_ID);
}

export function removeUserId() {
    Cookies.remove(EAE_USER_ID);
}

export function setUserName(user_name) {
    Cookies.set(EAE_USER_NAM, user_name);
}

export function getUserName() {
    return Cookies.get(EAE_USER_NAM);
}

export function removeUserName() {
    Cookies.remove(EAE_USER_NAM);
}

export function getProjectCode() {
    return Cookies.get(PROJECT_CODE);
}

export function setProjectCode(project_code) {
    Cookies.set(PROJECT_CODE, project_code);
}

export function isTestUser() {
    let user_id = getUserId()
    return user_id === "61cd6856a4640b946f8b456f" || user_id === "64b0f34ca4640b167b8b45b2" || user_id === "654de577a4640bc4308b45ee" || user_id === '66e4284b94c784bc388b4575';
}

export function setTargetType(target_type) {
    Cookies.set(TARGET_TYPE, target_type);
}

export function getTargetType() {
    return Cookies.get(TARGET_TYPE);
}

export function setAppId(app_id) {
    Cookies.set('eae-app_id', app_id)
}

export function getAppId() {
    return Cookies.get('eae-app_id')
}
export function setAccountName(account_name) {
    Cookies.set('eae_account_name', account_name)
}

export function getAccountName() {
    return Cookies.get('eae_account_name')
}

export function removeAccountName() {
    Cookies.remove('eae_account_name')
}


export function setSessionUrl(user_id) {
    Cookies.set('eae-sessionUrl', user_id)
}

export function getSessionUrl() {
    return Cookies.get('eae-sessionUrl')
}


export function setSessionName(user_id) {
    Cookies.set('eae-sessionName', user_id)
}

export function getSessionName() {
    return Cookies.get('eae-sessionName')
}

export function getUserIP(onNewIP) {
    let MyPeerConnection = window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection;
    let pc = new MyPeerConnection({
        iceServers: []
    });
    let noop = () => {};
    let localIPs = {};
    let ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g;
    let iterateIP = (ip) => {
        if (!localIPs[ip]) onNewIP(ip);
        localIPs[ip] = true;
    };
    pc.createDataChannel('');
    pc.createOffer().then((sdp) => {
        sdp.sdp.split('\n').forEach(function(line) {
            if (line.indexOf('candidate') < 0) return;
            line.match(ipRegex).forEach(iterateIP);
        });
        pc.setLocalDescription(sdp, noop, noop);
    }).catch((reason) => {});
    pc.onicecandidate = (ice) => {
        if (!ice || !ice.candidate || !ice.candidate.candidate || !ice.candidate.candidate.match(ipRegex)) return;
        ice.candidate.candidate.match(ipRegex).forEach(iterateIP);
    };
}