<template>
  <div id="app" style="width: 100%; margin: 0; padding: 0"
    :class="{ needScale: !['/accountform', '/appform', '/studentrequest', '/form/aeo', '/form/success'].includes($route.path) }">
    <header v-if="canShowHeader" class="head">
      <img :src="require('./assets/image/header-logo.png')" class="logo" />
      <div>
        <span style="margin-right: 15px">{{this.$UserData.account_name}} - {{
          this.$UserData.user_name }}{{ this.$UserData.account_country && this.$UserData.account_country!= '' ? "&nbsp [ " +
          this.$UserData.account_country +
          " ]": '' }}</span>
        <i class="el-icon-switch-button" @click="logout()" style="margin-right: 24px; cursor: pointer; color: #ffffff;"></i>
      </div>
    </header>
    <router-view></router-view>
  </div>
</template>
<script>
import {
  getUserName,
  removeUserId,
  removeUserName,
  getUserAccountCountry,
  removeUserAccountCountry,
  removeUserType,
  removeAccountId,
  getAccountName,
  removeAccountName,
} from "./utils/store";

export default {
  name: "app",
  data() {
    return {};
  },
  computed: {
    canShowHeader() {
      var show = false;

      if (
        this.$route.path.search("chatai") >= 0 ||
        this.$route.path.search("exam-ready") >= 0 ||
        this.$route.path.search("lecture") >= 0 ||
        this.$route.path.search("videoPlay") >= 0 ||
        this.$route.path.search("exam") >= 0 ||
        this.$route.path.search("official_course-pay") >= 0 ||
        this.$route.path.search("course_content") >= 0 ||
        this.$route.path.search("collection-list") >= 0 ||
        this.$route.path.search("login") >= 0 ||
        this.$route.path.search("appform") >= 0 ||
        this.$route.path.search("form/envirothon") >= 0 ||
        this.$route.path.search("form/aeo") >= 0 ||
        this.$route.path.search("form/ipsyo") >= 0 ||
        this.$route.path.search("form/ienvo") >= 0 ||
        this.$route.path.search("award") >= 0 ||
        this.$route.path.search("form/eae") >= 0 ||
        this.$route.path.search("form/bpa") >= 0 ||
        this.$route.path.search("form/ifo") >= 0 ||
        this.$route.path.search("form/wao") >= 0 ||
        this.$route.path.search("form/nim") >= 0 ||
        this.$route.path.search("form/wec") >= 0 ||
        this.$route.path.search("accountForm/nim") >= 0 ||
        this.$route.path.search("accountForm/wec") >= 0 ||
        this.$route.path.search("accountForm/bpa") >= 0 ||
        this.$route.path.search("accountForm/envirothon") >= 0 ||
        this.$route.path.search("form/registration") >= 0 ||
        this.$route.path.search("form/ibo") >= 0 ||
        this.$route.path.search("form/success") >= 0 ||
        this.$route.path.search("contactappform") >= 0 ||
        this.$route.path.search("accountform") >= 0 ||
        this.$route.path.search("studentrequest") >= 0 ||
        this.$route.path.search("registrationsuccess") >= 0 ||
        this.$route.path.search("up-pay") >= 0 ||
        this.$route.path.search("pass-pay") >= 0 ||
        this.$route.path.search("program-pay") >= 0 ||
        this.$route.path.search("certificate") >= 0 ||
        this.$route.path.search("newScore") >= 0

      ) {
        show = true;
      }

      return !show;
    },
  },
  mounted() {
    console.log('修改请求地址')
    this.$UserData.user_name = getUserName();
    this.$UserData.account_country = getUserAccountCountry();
    console.log('aaa'+getAccountName())
    this.$UserData.account_name = getAccountName();
  },
  methods: {
    logout() {
      this.$confirm("Are you sure you want to log out?", "Logout Confirmation", {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: "warning",
      })
        .then(() => {
          removeUserId();
          removeUserName();
          removeUserAccountCountry();
          removeUserType();
          removeAccountId();
          removeAccountName();
          sessionStorage.removeItem('currentIndex');
          this.$router.replace('/login')
        })
        .catch(() => { });
    },
  },
};
</script>

<style>
.el-popover {
  background-color: #FFF4F3 !important;
  border: none;
  word-break: keep-all !important;
  word-wrap: break-word !important;
  text-align: left !important;
}
</style>

<style lang="scss" scoped>
.needScale {
  width: 100vw;
}

.head {
  height: 50px;
  position: relative;
  font-size: 12px;
  background-color: var(--c-primary);
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .logo {
    width: 120px;
    height: 36px;
    margin-left: 24px;
  }
}

/deep/.el-main {
  margin: 0px;
  padding: 0px;
  background: #f8f8f8;
}

.el-container {
  margin: 0px;
  padding: 0px;
  // min-width: 1920px;
}

* {
  padding: 0;
  margin: 0;
}

#app {
  padding: 0;
  margin: 0;
  color: #606266;
}

.app-nav::-webkit-scrollbar {
  // display: none;
  /* Chrome Safari */
}

// ::-webkit-scrollbar {
//   /*滚动条整体样式*/
//   width: 8px;
//   /*高宽分别对应横竖滚动条的尺寸*/
//   height: 8px;
// }

// ::-webkit-scrollbar-thumb {
//   /*滚动条里面小方块*/
//   border-radius: 8px;
//   box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
//   background: #eaeaea;
// }

// ::-webkit-scrollbar-track {
//   /*滚动条里面轨道*/
//   box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
//   border-radius: 8px;
//   background: #fff;
// }

.el-menu {
  background: none;
}

.mobileConfirm {
  width: 80% !important;

  .el-message-box__container {
    display: flex;

    .el-message-box__status {
      position: relative;
      flex-shrink: 0;
      transform: none;
    }

    .el-message-box__message {
      padding-left: 10px;
    }
  }
}

.logout-icon {
    cursor: pointer;
    color: #ffffff;
}
</style>
